// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cl-brain-plt-primary: mat.define-palette(mat.$indigo-palette);
$cl-brain-plt-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cl-brain-plt-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cl-brain-plt-theme: mat.define-light-theme(
  (
    color: (
      primary: $cl-brain-plt-primary,
      accent: $cl-brain-plt-accent,
      warn: $cl-brain-plt-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cl-brain-plt-theme);

/* You can add global styles to this file, and also import other style files */
@import '~angular-tree-component/dist/angular-tree-component.css';

@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';
@import '~@ng-select/ng-select/themes/default.theme.css';

/* Utilities */
.c-pointer {
  cursor: pointer !important;
}
.c-default {
  cursor: default !important;
}

body {
  overflow: scroll !important;
}

//@import "src/assets/scss/overrides.scss";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
:root {
  --color-light-grey: #dee2e6;
  --color-purple: #410099;
  --color-grey: #5a5c75;

  .mat-step-header {
    pointer-events: none;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-step-label {
    font-weight: 600;
    font-size: 16px;
    color: #525f7f !important;
  }

  .mat-step-icon {
    background-color: var(--color-light-grey);
    border: 3px solid transparent;
    width: 32px;
    height: 32px;
  }

  .mat-step-icon-selected {
    background-color: var(--color-grey);
    border: 3px solid var(--color-light-grey);
  }

  .mat-step-icon-state-done {
    background-color: var(--color-grey);
    color: white;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
    border-top-width: 2px;
    border-top-color: var(--color-light-grey);
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
    border-top-width: 2px;
    border-top-color: var(--color-light-grey);
  }

  .mat-stepper-horizontal-line {
    border-top-width: 2px;
    border-top-color: var(--color-light-grey);
  }

  .last-edited-step-1 .mat-stepper-horizontal-line:first-of-type {
    border-top-color: var(--color-grey);
  }

  .last-edited-step-2 .mat-stepper-horizontal-line {
    border-top-color: var(--color-grey);
  }

  .last-edited-step-1 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(1)::after,
  .last-edited-step-1 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(3)::before,
  .last-edited-step-2 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(1)::after,
  .last-edited-step-2 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(3)::before,
  .last-edited-step-2 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(3)::after,
  .last-edited-step-2 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(5)::before,
  .last-edited-step-3 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(1)::after,
  .last-edited-step-3 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(3)::before,
  .last-edited-step-3 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(3)::after,
  .last-edited-step-3 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(5)::before,
  .last-edited-step-3 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(5)::after,
  .last-edited-step-3 .mat-horizontal-stepper-header-container .mat-step-header:nth-child(7)::before {
    border-top-color: var(--color-grey);
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before,
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
    width: calc(50% - 16px);
  }
}

.fw-bold {
  font-weight: bold !important;
}

.theme-green .bs-datepicker-head {
  background-color: #410099 !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*='select-']:after,
.theme-green .bs-datepicker-body table td[class*='select-'] span:after {
  background-color: #410099 !important;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #410099 !important;
}

.form-control {
  color: #52557b !important;
}

.mat-cell,
.mat-footer-cell {
  color: #52557b;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  color: #410099;
  background-color: #f1f2fa !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle,
a.text-white:hover,
a.text-white:focus {
  color: #410099 !important;
  background-color: #f1f2fa !important;
}

.btn:hover {
  box-shadow: none !important;
  transform: none !important;
}

.report-search-input {
  border-radius: 5px;
  border: 1px solid #cccccc;
  font-size: 14px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

angular-editor {
  width: 100%;
}
