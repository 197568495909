.tree-node-level-1 {
  //  padding: 1.5rem;
}

.node-content-wrapper {
  border: 1px solid darken($primary, 20%);
  border-radius: 5px;
  padding: 0.25rem;
  max-width: 30rem;
  min-width: 5rem;

  &:hover {
    background-color: lighten($primary, 10%);
  }

  &.node-content-wrapper-active {
    background-color: lighten($primary, 25%);
    &:hover {
      background-color: lighten($primary, 30%);
    }
  }
}

/* Branch lines based on a theme from https://angular2-tree.readme.io/docs/styling */

.node-content-wrapper,
.tree-children {
  position: relative;
}

.node-content-wrapper::before,
.tree-children::after {
  content: '';
  position: absolute;
}

.tree-node-level-1 > tree-node-wrapper > .node-wrapper > .node-content-wrapper::before {
  display: none;
}

.tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
  width: 25px;
}

.tree-children::after {
  border-left: 1px solid $light;
  height: 100%;
  top: -15px;
  left: -15px;
}

tree-node:last-child > .tree-node > .tree-children::after {
  border-left: none;
}

.toggle-children {
  z-index: 1;
}

input[type='checkbox'].tree-node-checkbox {
  margin-top: 8px;
  width: 17px;
  height: 17px;
}

tree-node-wrapper > .node-wrapper > tree-node-expander {
  min-width: 25px;

  > span.toggle-children-wrapper > span.toggle-children {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 12px;
  }
}
