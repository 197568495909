.navbar-collapse {
  flex-grow: 0;
}


.btn-circle {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
}

.animate {
  transition: .085s all ease-in;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-clr-red {
  background: #E46473;
}

.bg-clr-green {
  background: #2DCE98
}

.bg-clr-blue {
  background: #11CDEF;
}

.bg-clr-purple {
  background: #7764E4;
}

.bg-clr-navy {
  background: #172B4D;
}

.bg-clr-navy-light {
  background: #607aa6;
}

.bg-clr-gray {
  background: #8898AA;
}

.bg-clr-yellow {
  background: #feb969;
}

.bg-clr-pink {
  background: #e234ba;
}

.hov-clr-red {
  &:hover {
    color: #E46473;
  }
}

.hov-clr-green {
  &:hover {
    color: #2DCE98
  }
}

.hov-clr-blue {
  &:hover {
    color: #11CDEF;
  }
}

.hov-clr-purple {
  &:hover {
    color: #7764E4;
  }
}

.hov-clr-navy {
  &:hover {
    color: #172B4D;
  }
}

.hov-clr-navy-light {
  &:hover {
    background: #607aa6;
  }
}

.btn-clear {
  background: transparent;
  box-shadow: 0;
  &:hover {
    background: transparent;
    box-shadow: 0;
    color: #E46473;
  }
}

.c-pointer {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes fadeLoop {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

.fade-loop {
  opacity: 1.0;
  animation: fadeLoop 2s infinite;
}
