// Global Overrides
a {
  color: #410099;
}
p {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #52557b;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

h5 {
  font-size: 14px;
  font-weight: 600;
}

.text-primary-outline {
  color: #410099;
}

// Header
.navbar.navbar-top {
  height: 50px;
  position: sticky;
  top: 32px;
  .container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
#navbar-main {
  background: #fff;
  z-index: 1;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  background-color: #f1f2fa;
}

.avatar-sm {
  width: 28px;
  height: 28px;
}
.avatar-img {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

// sidenav
:host ::ng-deep {
  .g-sidenav-hidden {
    .sidenav-copyright {
      display: none;
    }
  }
}
.g-sidenav-hidden {
  .sidenav-copyright {
    display: none !important;
  }
  &.g-sidenav-show {
    .sidenav-copyright {
      display: block !important;
    }
  }
}
.sidenav-header {
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
}
.sidenav-toggler-line {
  background-color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '/';
  padding-right: 3px;
  color: #ccc;
}

// bootstrap nav-pills extention
.nav-pills--group {
  .nav {
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 0.375rem;
  }

  .nav-item {
    padding-right: 0 !important;
    .nav-link {
      box-shadow: none;
      border-radius: 0;
      font-size: 14px;
      border: 1px solid #410099;
      border-left: 0;

      &.active {
        color: #fff;
        background: #410099;
        &:hover {
          color: #fff;
        }
      }
    }
    &:first-child {
      .nav-link {
        border-left: 1px solid #410099;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &:last-child {
      .nav-link {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

// list group
.list-group-item {
  padding: 0.5rem 1rem;

  p,
  a {
    font-size: 14px;
    line-height: 1.2;
  }
}

// Card
.card {
  &-header {
    padding: 0.5rem 1rem;
  }
}

.hl-messages-list-wrapper {
  p {
    font-size: 14px;
    line-height: 1.2;
  }
  .card-body {
    padding: 1rem;
  }
  .assistant-message {
    background-color: #fafafa !important;
    padding: 0.75rem !important;
  }
  .alert.alert-default {
    padding: 0.5rem;
  }
  .fa,
  .fas {
    font-size: 1rem;
  }
  .mx-1 {
    color: #52557b;
  }
}

#all-conversations-list {
  li {
    margin-bottom: 0;
  }
  .last-update-text {
    color: #52557b;
  }
}

// Buttons
.btn-primary {
  border-color: #410099;
  background-color: #410099;
  box-shadow: none !important;
}
.btn-outline-primary {
  border-color: #410099;
  color: #410099;
}
